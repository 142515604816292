import React from 'react';
import Calendar from '../views/Calendar';

const CalendarContainer = () => {
    return (
        <Calendar/>
    );
};

export default CalendarContainer;
